
@import '~antd/dist/antd.css';

body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

.menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a{
  padding: 10px 15px;
  text-transform: uppercase;
  color: #494949;
}
.menuCon .ant-menu-item a:hover, .menuCon .ant-menu-submenu-title a:hover{
  color: #644b57;
  font-weight: 500;
}
a {
  color: #9c7a8b;
}
a:hover {
  color: #F3EFF1;
}
.search-title{
  text-transform: capitalize;
  color: #494949;
}
.search-section {
  background-color: #F3EFF1;
  padding: 6% 0;
}

.ant-btn-primary{
  background-color: #9C7A8B;
  border-color: #9C7A8B;
  color: #FFFFFF !important;
}
.ant-btn-primary:hover, .ant-btn-primary:active{
  background-color: #644b57;
  border-color: #644b57;
}
.ant-btn{
  color: #9C7A8B;
  /* border-color: #9C7A8B;     
  text-transform: uppercase; */
}
.ant-btn > .anticon {
  color: #fff;
}
.ant-btn:hover, .ant-btn:active{
  color: #644b57;
  border-color: #644b57;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #9C7A8B;
}
.ant-pagination-item-active:focus, .ant-pagination-item-active:hover{
  background-color: #644b57;
  border-color: #644b57;  
}
/* .ant-pagination-item-active a, .anticon {
  color: #9C7A8B; //don't uncomment its breaking reveiws
} */
.ant-pagination-item-active a:hover {
  color: #FFF;
}
.ant-table-tbody > tr:hover, .ant-table-tbody:hover, tbody:hover {
  background-color: #e4e4e4 !important;
}
.menuCon{
  width: 100%;
  float: left;
  padding: 10px 0;
}
.ant-input:hover, input[type="text"]:hover, input[type="password"]:hover, input[type="number"]:hover, textarea:hover {
  border: 1px solid #644b57 !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active, .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover{
  color: #644b57;
}
.title-spacing{
  margin: 4% 0;
}

.featured-section{
  margin: 4% 0 0 0;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #644b57;
  border-bottom: 2px solid #644b57;
} 
/* /// */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.menuBar{
  box-shadow: 0 0 30px #f3f1f2;
}

.menuCon .ant-menu-item{
  padding: 0px 5px;
}
.menuCon .ant-menu-submenu-title{
  padding: 10px 20px;
}

.menuCon .ant-menu-horizontal{
  border-bottom: none;
  margin-top: 11px;
}
.menuCon .leftMenu{
  float: left;
}
.menuCon .rightMenu{
  float: right;
}
.menuBar{
  clear: both!important;
    float: none!important;
}
.menuCon .ant-menu-horizontal{
  line-height: 23px;
}
.barsMenu{
  position: absolute;
  display: none;
  margin-top: 2%;
}

.search-section input{
    height: 45px;
    border-radius: 10px;
    width: 270px;
}
.suggested-search-box{
  height: 189px;
  overflow: hidden;
}

.second-container{
  min-height: 66vh;
  padding: 0 1% 1% 1%;
}
.search-press{
  width: 50%;
}

.second-container-static-pages{
  min-height: 66vh;
}

.remove-margin .ant-row, .remove-margin-deal-page{
   margin-left: 0px !important;
   margin-right: 0px !important;

}

.page-description{
  text-align: justify;
}

.doctor-auth-pop-up .ant-modal-body{
  padding: 0px;
}
.doctor-auth-pop-up .ant-modal-close{
   margin-top: 10%;
}
.doctor-auth-tabs .ant-tabs-nav, .doctor-booking-tabs .ant-tabs-nav  {
   width: 100%;
}
.doctor-auth-tabs .ant-tabs-tab{
   width: 50%;
}

.doctor-booking-tabs .ant-tabs-tab{
   width: 25%;
}

.doctor-reviwes-tab .ant-tabs-tab{
   width: 50%;
}
.doctor-booking-tabs .ant-tabs-bar{
   padding: 0px;
   margin-bottom: 0;
}

.doctor-booking-tabs .ant-list-bordered{
   border-radius: 0px;
}
.doctor-auth-tabs .ant-tabs-nav-container-scrolling, .doctor-booking-tabs .ant-tabs-nav-container-scrolling{
   padding-right: 0;
   padding-left: 0;
}
.doctor-auth-tabs .ant-tabs-tab-next, .doctor-auth-tabs .ant-tabs-tab-prev, .doctor-booking-tabs .ant-tabs-tab-next, .doctor-booking-tabs .ant-tabs-tab-prev{
   display: none;
}
.doctor-auth-tabs .doctor-auth-forms{
   padding: 20px 35px 20px 35px;
}

.doctor-auth-tabs .doctor-auth-forms input[type=text], .doctor-auth-tabs .doctor-auth-forms input[type=number],
.doctor-auth-tabs .doctor-auth-forms input[type=password]{
   border-radius: 12px;
   height: 50px;
}

.doctor-auth-tabs .doctor-auth-forms .ant-upload{
   width: 100%
}

.doctor-auth-tabs .doctor-auth-forms .ant-upload button{
   width: 100%
}

.user-select-row{
  margin:10%;
}

.appointment-list {
  display: block !important;
}

.appiontment-reason{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.appointment-patient-list{
  font-size: 0.8em;
}
.view-patient-button{
  cursor: pointer;
}
.user-name{
  text-transform: capitalize;
}

.patient-paractice-images img {
   width: auto;
   height: 100%;
   max-width: 256px;
   object-fit: contain;
}

.payment-form .ant-calendar-picker{
  width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .search-section .ant-form-item-control{
    padding-left: 25px;
  }
} 

@media screen and (min-width: 990px) and (max-width: 1200px) {
  .search-section input{
    width: 199px;
  }
}  


@media screen and (min-width: 1023px) {
  .search-section-margin{
    margin-left: -5.7% !important;
    margin-right: -5.7% !important;
  }
}  

@media screen and (min-width: 768px) and (max-width: 989px) {
  .search-section input{
    width: 149px;
  }
}  


@media(max-width: 767px){
  .site-logo{
    display: none;
  }
  .barsMenu{
    display: inline-block;
  }
  .menu-section{
    margin-left: 1%;
  }
  .leftMenu{
    display: none;
  }
  .welcome-section{
    display: none;
  }
  .menu-section .ant-form-inline .ant-form-item {
     display: block; 
     margin-right: 0px; 
     margin-bottom: 10px; 
  }
  .search-section input{
    width: 100%;
  }
  .search-press{
    width: 90%;
  }
}

@media(min-width: 769px){
  .first-col-padding{
    padding-right: 2%;
  }
}